//import vue from 'vue'
//import Vuex from 'vuex'
import http from "../http-common";
//import router from '../router'
//import createPersistedState from "vuex-persistedstate";
//import auth from "./auth";
import { createStore } from "vuex";
//import createPersistedState from 'vuex-persistedstate';
import codes from "./codes";

//vue.use(Vuex);

const app = createStore({
  state: {
    id: "",
    grade: "",
    phone: "",
    name: "",
    teacherid: "",
    role: "",
    isLogin: false,
    classid: "",
    isAuthenticated: false,
    userData: null
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setLoginState(state, userData) {
      state.isAuthenticated = true;
      state.userData = userData;
    },
    setLogoutState(state) {
      state.isAuthenticated = false;
      state.userData = null;
    },
    loginToken: function (state, payload) {
      state.id = payload.id;
      state.grade = payload.grade;
      state.id = payload.id;
      state.phone = payload.phone;
      state.name = payload.name;
      state.teacherid = payload.teacherid;
      state.role = payload.role;
      state.classid = payload.classid;
      state.isLogin = true;
      console.log("loginToken=", payload);

    },
    logout: function (state) {
      state.isLogin = false;
      // this.getters = null;
      //if(state.token){
      state.token = "";
      //}
    },
    loginCheck: function (state) {
      http
        .get("/api/login", {
          headers: {
            "x-access-token": state.token,
          },
        })
        .then((res) => {
          //console.log(res);
          state.role = res.data.token.role;
        });
    },
  },
  //plugins: [createPersistedState()],
  actions: {
    login({ commit }, userData) {
      console.log("userData", userData);
      commit('setLoginState', userData);
      localStorage.setItem('userData', JSON.stringify(userData));
    },
    logout({ commit }) {
      commit('setLogoutState');
    },
    checkAuth({ commit }) {
      const userData = localStorage.getItem('userData');
      if (userData) {
        commit('setLoginState', JSON.parse(userData));
      }
    }
  },
  modules: {
    codes // 모듈 등록
  },
  getters: {
    doneId: function (state) {
      return state.id;
    },
    doGrade: function (state) {
      return state.grade;
    },
    doPhone: function (state) {
      return state.phone;
    },
    doneName: function (state) {
      return state.name;
    },
    doTecherid: function (state) {
      return state.teacherid;
    },
    doRole: function (state) {
      return state.role;
    },
    doToken: function (state) {
      return state.token;
    },
    doClassid: function (state) {
      return state.classid;
    },
  },
});


export default app;
